import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import DemoPage from '../ui/pages/DemoPage'
import { Formik } from 'formik'
import { schema, handleSubmit, initialValues } from '../integrations/demo'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/de.json'

const Demo = props => (
  <>
    <HelmetComponent
      title={metaTags.demoTitle}
      description={metaTags.demo}
      page="de/demo"
    />
    <AppWrapper {...props} lang="de">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {formikProps => <DemoPage formik={formikProps} />}
      </Formik>
    </AppWrapper>
  </>
)

export default Demo
