import * as Yup from 'yup'
import axios from 'axios'
import { navigate } from 'gatsby'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { INTEGRATIONS_API } from '../config/constants'

export const initialValues = {
  name: '',
  email: '',
  message: '',
}

export const schema = Yup.object().shape({
  email: Yup.string().required('validation:required').email('validation:email'),
  name: Yup.string().required('validation:required').min(4, 'validation:short'),
  company: Yup.string()
    .required('validation:required')
    .min(4, 'validation:short'),
})

export async function handleSubmit(values, { setSubmitting }) {
  setSubmitting(true)
  await axios.post(`${INTEGRATIONS_API}/demo`, values)
  setSubmitting(false)
  trackCustomEvent({
    category: 'Website Events',
    action: 'Demo request',
  })
  navigate('/contact-success')
}
